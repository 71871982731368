import React from "react";
import AboutHero from "../components/AboutPage/AboutUsHero";
import ContactbannerAbout from "../components/AboutPage/contactBannerAbout";
import FaqSection from "../components/AboutPage/FAQSection";
import SiteSEO from "../components/siteSEO";
// import TeamSection from "../components/teamSection";

function AboutPage() {
  return (
    <>
      <SiteSEO
        title={"About | MVP Tribe"}
        description={
          "We take Pride in making Technology work for you.. MVP Tribe is a unit of Trusty Bytes Private Limited (www.trustybytes.com). The unit was primary set up to cater to an audience of Subject Matter experts and enterprises who have a passion for Products and Feature development."
        }
        keywords={
          "MVP Development , Blog, MVP Insights, Posts, Techblog, business, MVP Tribe"
        }
        image={""}
      />
      <div style={{ marginTop: "20px" }}></div>
      <AboutHero />
      {/* <TeamSection/> */}
      {/* Added Later */}
      <FaqSection />
      <ContactbannerAbout />
    </>
  );
}
export default AboutPage;
