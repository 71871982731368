import React from "react";
import ContactModalButton from "../utils/contactModal";
import banner from '../../img/aboutbanner.png'

function AboutHero() {
  return (
    <div className="container px-6 py-16 mx-auto">
      <div className="items-center lg:flex">
        <div className="w-full lg:w-1/2">
          <div className="lg:max-w-lg">
            <h1 className="text-2xl font-sans font-semibold text-gray-800 py-3 uppercase text-left dark:text-white lg:text-3xl">
              What we do is what make us Who we are
            </h1>
            <p className="mt-2 font-sans text-gray-600 dark:text-gray-400">
              We take Pride in making Technology work for you.. MVP Tribe is a
              unit of Trusty Bytes Private Limited (www.trustybytes.com). The
              unit was primary set up to cater to an audience of Subject Matter
              experts and enterprises who have a passion for Products and
              Feature development.
            </p>
            <ContactModalButton
              className="w-full px-3 py-2 mt-6 text-xs font-medium text-white uppercase transition-colors duration-200 transform bg-blue-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
              buttonName={"Let's Talk"}
            />
          </div>
        </div>

        <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
          <img
            className="w-full h-full lg:max-w-2xl"
            src={banner}
            alt="about banner"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutHero;
