import React, { useState } from "react";
import notificationAPI from "../../services/notification";
import ReactiveButton from "reactive-button";

function AboutContactForm() {
  const [state, setState] = useState("idle");
  const onSubmit = (event) => {
    event.preventDefault();

    setTimeout(() => {
      setState("success");
    }, 2000);

    const name = event.target.name.value;
    const email = event.target.email.value;
    const message = event.target.message.value;

    setState("loading");
    //Sending Notification Function
    notificationAPI(name, email, message);
  
  };
  return (
    <form className="flex w-full max-w-sm space-x-3" onSubmit={onSubmit}>
      <div className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
        <div className="mb-6 text-3xl font-light text-center text-gray-800 dark:text-white">
          Contact us !
        </div>
        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
          <div className="col-span-2 lg:col-span-1">
            <div className=" relative ">
              <input
                type="text"
                id="name"
                className=" form-control rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className=" relative ">
              <input
                type="email"
                id="email"
                className="form-control rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div className="col-span-2">
            <label className="text-gray-700" htmlFor="message">
              <textarea
                className="form-control flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                id="comment"
                placeholder="Enter your comment"
                name="message"
                rows="5"
                cols="40"
                required
              ></textarea>
            </label>
          </div>
          <div className="col-span-2 text-center">
            <ReactiveButton
              buttonState={state}
              idleText="Submit"
              loadingText="Submitting"
              successText="Thanks for being awesome! We have received your message"
              id="modal-switch"
              className="form-control py-2 px-4 btn btn-primary align-center hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
}
export default AboutContactForm;