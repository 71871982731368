import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FaqSection({
  faqs = [
    {
      question: "Do you co-partner in investing in Product development ?",
      answer:
        "We are currently not co-investing in Product development. We solely act as a technological partner to support the success of our clients.",
    },
    {
      question: "What do your engagement model and commercials look like?",
      answer:
        "We are open to flexible engagement models that work best for our clients. While we prefer a fixed price engagement for product development services and Time-based billing for consulting services, we are flexible to customize per client needs.",
    },
    {
      question: "Can you provide references to past work?",
      answer: "Yes, we can provide references upon request.",
    },
    {
      question: "How is my idea protected from leaks? ",
      answer:
        "Since Trust is the foundation of our company, it permeates all aspects of our interactions. We sign NDAs and all necessary contracts to provide our clients the assurance they need to work with us.",
    },
    {
      question: "What are your primary success factors? ",
      answer:
        "Establishing Trust will be essential to any participation at MVP Tribe, leading to transparency and openness. In order to better understand and align with the goals of the businesses, we expect our clients to be reachable and willing to provide facts and answers to perhaps silly queries. We endeavor to accelerate the decision-making process.",
    },
    {
      question: "Do you offer services across all technology stacks? ",
      answer:
        "We have in-house expertise in some full-stack skill sets, like MERN, MEAN, etc. We have also worked with various cloud technologies, like AWS. The business need determines the technological stack, and in case of non-availability of skillset, we hire or engage contractors/freelancers to deliver the work. ",
    },
    {
      question: "Do you engage sub-contractors or freelancers?   ",
      answer:
        "Yes, we engage freelancers as applicable to the requirement of the project.",
    },
    {
      question: "What is the size of projects that you take? ",
      answer:
        "We handle projects that take between 200 and 800 hours in a single MVP. Anything more is usually broken down into two or more MVP phases. ",
    },
  ],
}) {
  return (
    <div className="px-10">
      <div className="col text-start font-sans text-md-center flex-column justify-content-center flex-wrap align-items-md-center align-items-xxl-center">
        <h1
          style={{
            fontSize: "36px",
            color: "Black",
            fontWeight: "bold",
            textAlign: "center",
            paddingLeft: "14px",
            paddingBottom: "10px",
          }}
        >
          Frequently asked Questions
        </h1>
      </div>
      {faqs.map((faq) => {
        return (
          <Accordion className="py-2 ">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-sans">
                <strong >{faq.question}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="font-sans">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default FaqSection;
